import React from 'react'
import Button from '../utils/Button'
export default function TeacherTile(props) {
    return (
        <div className="lg:grid lg:grid-cols-2 flex flex-col justfiy-center items-center lg:items-start lg:gap-8 w-full mb-14 lg:mb-0 last:mb-0">
            <div className="w-full relative flex flex-col justfiy-center md:items-start items-center">
                <img className="lg:h-429px md:h-92 lg:w-full" src={props.image} alt="" />
                {
                    props.credit ? <p className="text-xs text-white absolute bottom-2 md:left-4 left-8 right-auto">{props.credit}</p> : null
                }
            </div>

            <div className="flex flex-col justify-start md:items-start items-center">
                <h4 className="text-black font-bold text-md mt-7 lg:mt-0">{props.name}</h4>
                <p className="text-xs font-semibold" style={{ color: "#000" }}>{props.title}</p>
                <div dangerouslySetInnerHTML={{ __html: props.description }} className="md:mt-2 mt-4 leading-tight text-center md:text-left w-full md:w-11/12 4xl:max-h-[380px] 2xl:max-h-[15rem] xl:max-h-[18.3rem] lg:max-h-[10rem] max-h-auto text-ellipsis overflow-hidden" style={{ color: "#616161", fontSize: "13px" }}></div>
                <div className="flex flex justify-between w-full mt-2">
                    <a href={props.pdf} target="_blank" rel="nofollow">
                        <p className="text-xs font-bold text-mustard mr-2 cursor-pointer hover:scale-x-105 transform transition-all duration-300 ease">read more...</p>
                    </a>
                    <a href={props.followLink} target="_blank" rel="nofollow">
                        <img src="/images/youtube.svg" alt="Youtube" className="mr-6 w-16 hover:scale-x-105 transform transition-all duration-300 ease" />
                    </a>
                </div>

                <Button type="externalLink" href={props.link} style={{ marginTop: "12px" }}>{props.buttonText}</Button>
            </div>
        </div>
    )
}
