export const testimonialData = {
    data: {
        content: [
            {
                id: 1,
                name: "Samyukta Ninan",
                title: "Vocal Music",
                stars: 5,
                description: "‘Kaladhwani is an interactive space that enables you to experience a holistic way to learn, understand and appreciate the performing arts. I am learning music and find the methodology used to impart training very refreshing, engaging and empowering",
                image: "/images/person.png",
            },
            {
                id: 2,
                name: "Parvati Prabha",
                title: "Bharatanatyam",
                stars: 5,
                description: "I consider myself quite fortunate to have got an opportunity to train under an eminent dancer Radhika, who needless to say, epitomizes grace, beauty, and perfection. Through her extremely well-thought and structured sessions, she prepares the students with needed conditioning of the body and adopts a very holistic approach in teaching the dance form. Her firm yet kind attention to every student in the class is quite endearing as well.",
                image: "/images/person.png",
            },
            {
                id: 3,
                name: "Karishma Arora",
                title: "Bharatanatyam",
                stars: 5,
                description: "Kaladhwani is a wonderful learning space with an excellent teaching-learning methodology. Radhika ma'am is a perfectionist when it comes to explaining complex concepts into simpler terms. Moreover, the modules created by her are a perfect balance of both theoretical and practical aspects which makes the learning process enjoyable.",
                image: "/images/person.png",
            },
            {
                id: 4,
                name: "Ragini Vats",
                title: "Vocal Music",
                stars: 5,
                description: "Learning at Kaladhwani is a very enriching experience for me because I don't just learn music as it is, but the entire journey of music in a holistic way. My teacher teaches with a lot of patience and knowledge and creates a very comfortable and interactive space.",
                image: "/images/person.png",
            },
        ],
    },
};
