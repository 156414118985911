import React from 'react'
import ReactPlayer from 'react-player/youtube'
import H3 from '../../utils/H3'
import { GiMusicalScore } from "@react-icons/all-files/gi/GiMusicalScore";
export default function Our4c(props) {
    return (
        <div className="bg-transparent md:pt-6 md:pb-3 flex justify-center flex-col items-center w-full">
            <div className="md:flex-row flex justify-center flex-col items-center w-full">
                <div className="lg:w-450px md:h-326px h-288px mb-8 md:mb-0 w-full rounded-4xl">
                    <ReactPlayer controls={true} url={props.video} className="react-player" width="100%" height="100%" />
                </div>
                <div className="pl-8 md:pl-12 flex justify-center flex-col items-start lg:w-4/12 md:w-full">
                    <H3 color="text-red" uppercase className={`${props.home ? 'lg:w-full' : 'lg:w-11/12'}`} bottom="mb-5">{props.title}</H3>
                    <div className="grid grid-rows-4 grid-cols-1">
                        <div className="flex">
                            <img src="/images/insurance.svg" alt="" className="w-8 h-8 mr-10 object-contain" />
                            <H3 color="text-black" className="font-normal" bottom="mb-5">Conditioning</H3>
                        </div>
                        <div className="flex">
                            <img src="/images/voice-control.svg" alt="" className="w-8 h-8 mr-10 object-contain" />
                            <H3 color="text-black" className="font-normal" bottom="mb-5">Command</H3>
                        </div>
                        <div className="flex">
                            <img src="/images/creative-brain.svg" alt="" className="w-8 h-8 mr-10 object-contain" />
                            <H3 color="text-black" className="font-normal" bottom="mb-5">Creativity</H3>
                        </div>
                        {props.music ?
                            <div className="flex">
                                <GiMusicalScore className="w-8 h-8 mr-10 text-black" />
                                <H3 color="text-black" className="font-normal" bottom="mb-0">Composition</H3>
                            </div> :
                            <div className="flex">
                                <img src="/images/dancer.svg" alt="" className="w-8 h-8 mr-10 object-contain" />
                                <H3 color="text-black" className="font-normal" bottom="mb-0">Choreography</H3>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
