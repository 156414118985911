import React from 'react'
import H1 from '../../utils/H1';
import H2 from '../../utils/H2';
import H3 from '../../utils/H3';
import Button from '../../utils/Button';
const BannerContent = ({ title, subtitle, description }) => {
    return (
        <div className="bannerContent flex flex-col md:justify-center justify-start md:items-start items-center md:w-6/12 w-full md:px-0 px-4 overflow-hidden">
            <H1 className="mb-0 text-red text-center md:text-left">Kaladhwani</H1>
            {/* <H2 color="text-black" bottom="mb-0" className="md:text-left text-center">Academy of Performing Arts</H2> */}
            <h3 className="text-black transition-all duration-300 ease font-bold text-2xl md:w-10/12 w-full mb-0 md:text-left text-center">Academy of Performing Arts</h3>
            <p className="text-black font-light text-lg transition-all duration-300 ease">Live and Online Classes</p>
            <div className="banner-button transition-all duration-300 ease">
                <Button type="internalLink" to="/book-free-trial" style={{ marginTop: "20px" }}>Book a FREE Trial</Button>
            </div>
        </div>
    );
};
export default function BannerSlide(props) {
    return (
        <div className={`w-full flex flex-col md:justify-center justify-start md:pt-0 pt-8 items-end 4xl:h-[688px] h-488px ${props.image} bg-cover bg-no-repeat 4xl:bg-center 4xl:bg-cover lg:bg-center md:bg-bannerTablet bg-center`}>
            <BannerContent />
        </div>
    )
}

