import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactStars from "react-rating-stars-component";
import { testimonialData } from "../data/testimonial-data";
import { AiOutlineRight } from "@react-icons/all-files/ai/AiOutlineRight";
import { RightChevron } from "../utils/icons/right-chevron";
import { LeftChevron } from "../utils/icons/left-chevron";
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="absolute inset-y-0 md:-right-7 -right-8 w-12 h-full flex justify-center items-center z-1">
            <div className="bg-red rounded-full md:w-12 md:h-12 w-10 h-10 flex justify-center items-center cursor-pointer" onClick={onClick}>
                <RightChevron fill="#fff" />
            </div>

            {/* <AiOutlineRight className="cursor-pointer text-3xl" onClick={onClick} /> */}
        </div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="absolute inset-y-0 md:-left-7 -left-8 w-12 h-full flex justify-center items-center z-1">
            <div className="bg-red rounded-full md:w-12 md:h-12 w-10 h-10 flex justify-center items-center cursor-pointer" onClick={onClick}>
                <LeftChevron fill="#fff" />
            </div>
        </div>
    );
}
const TestimonialSlide = (props) => {
    return (
        <div className="bg-white rounded-2xl h-372px flex flex-col justify-start items-start w-11/12 m-auto overflow-hidden p-4">
            <p className="text-sm leading-snug" style={{ color: "#616161" }}>{props.data.description}</p>
            <div className="flex justify-start items-center mt-auto w-full">
                <div className="flex flex-col">
                    <h3 className="text-black font-semibold text-md">{props.data.name}</h3>
                    <p className="text-xs mb-8" style={{ color: "#616161" }}>{props.data.title}</p>
                    <div className="flex flex-row items-center">
                        <ReactStars
                            count={5}
                            value={props.data.stars}
                            size={20}
                            activeColor="#E8983F"
                            edit={false}
                        />
                    </div>
                </div>
                <img src={props.data.image} alt="" className="w-20 h-20 rounded-full ml-auto mr-0 object-contain" style={{ backgroundColor: "#F5F5F5" }} />
            </div>
        </div>
    );
};

export default function TestimonialSlider(props) {
    const testimonials = testimonialData.data.content;
    const settings = {
        dots: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        swipeToSlide: true,
        // fade: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    return (
        <div className={`flex flex-col justify-start items-start lg:w-8/12 md:w-11/12 w-full px-8 py-12 bg-lightOrange rounded-3xl bg-testimonialBg md:bg-contain bg-cover bg-left relative`}>
            <Slider {...settings} className="w-full">
                {testimonials.map((row) => (
                    <TestimonialSlide data={row} key={"testimonial" + row.id} />
                ))}
            </Slider>
            <p className="text-red text-200px transform -scale-x-100 leading-none font-julee absolute top-0 right-0">“</p>
        </div>
    )
}

