import * as React from "react"
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';

const Counter = ({ target, title, duration }) => {
    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true,
    });

    return (
        <li className='md:w-1/3 w-full p-2 md:p-4 text-center flex flex-col items-center counter-block' ref={ref}>
            <CountUp
                start={0}
                end={inView ? target : 0}
                duration={duration}
                useEasing={true}
                suffix="+"
            >
                {({ countUpRef }) => (
                    <span className='text-4xl font-bold md:text-5xl text-mustard w-full md:border-r-1 border-black ' ref={countUpRef} />
                )}
            </CountUp>
            <p
                className={`font-normal text-black text-lg animation md:mt-4 mt-2 ${inView === true ? 'opacity-100 transform-show' : 'opacity-0 transform-hide'}
                    `}>
                {title}
            </p>
            <div className="md:hidden w-4/12 h-1px bg-black m-auto mt-8 mb-4 bottomline">

            </div>
            <style jsx>{`
				.delay {
					transition-delay: ${duration}s;
				}
				.transform-hide {
					transform: translate3d(0, 1rem, 0);
				}
				.transform-show {
					transform: translate3d(0, 0, 0);
				}
			`}</style>
        </li>
    );
};

export default Counter;