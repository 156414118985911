import React from 'react'
import H3 from '../utils/H3';
import Counter from './Counter';
export default function CounterSection() {
    return (
        <div className="bg-lightOrange py-12 flex justify-center flex-col items-center">
            <H3 color="text-black" className="text-center uppercase " bottom="mb-4">Join The Kaladhwani Revolution</H3>
            <ul className='flex md:flex-row flex-col md:justify-evenly lg:w-10/12 w-full'>
                <Counter target={15} title='Years experience in Arts' duration={5} />
                <Counter target={3000} title='Classes taken' duration={7} />
                <Counter target={150} title='Students' duration={5} />
            </ul>
        </div>
    )
}
